import { getSingleBySlug } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Slot } from "../../types";
import { ref } from "vue";
import slotHelpers from "./slotHelpers";

export default function fetchSlot(slug: string | string[]) {
  const slot = ref<API_Slot>({} as API_Slot);
  const loading = ref<boolean>(true);

  const fetchSlot = (): Promise<void | API_Slot> => {
    return getSingleBySlug<API_Slot>(GET.SLOTS, slug).then((result) => {
      loading.value = false;
      slot.value = camelizeKeys(result);
    });
  };

  const { canUserPlay, calcRating, getProCons, getFeatures } = slotHelpers(
    slot
  );

  fetchSlot();

  return {
    slot,
    fetchSlot,
    loading,
    canUserPlay,
    calcRating,
    getProCons,
    getFeatures,
  };
}
